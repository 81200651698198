.image_class {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 5px;

    animation-name: example;
    animation-duration: 500s;
    animation-iteration-count: infinite;
}

@keyframes example {
    0% {
        background-image: url(http://iucncoin.io/resimler/1.jpg);
    }

    10% {
        background-image: url(http://iucncoin.io/resimler/2.jpg);
    }

    20% {
        background-image: url(http://iucncoin.io/resimler/3.jpg);
    }

    30% {
        background-image: url(http://iucncoin.io/resimler/4.jpg);
    }

    40% {
        background-image: url(http://iucncoin.io/resimler/5.jpg);
    }

    50% {
        background-image: url(http://iucncoin.io/resimler/6.jpg);
    }

    60% {
        background-image: url(http://iucncoin.io/resimler/1.jpg);
    }

    70% {
        background-image: url(http://iucncoin.io/resimler/2.jpg);
    }

    80% {
        background-image: url(http://iucncoin.io/resimler/3.jpg);
    }

    90% {
        background-image: url(http://iucncoin.io/resimler/5.jpg);
    }

    100% {
        background-image: url(http://iucncoin.io/resimler/6.jpg);
    }
}
.color_black{
    color: #212529;
}
.home-title{
    color: #1fc700 !important;
    font-size: 4.125rem;
}
.bg-gray {
    background-color: #40587487;
    padding: 6px;
}
.pr{
    background: #80828d;
    color: white;
    padding: 1px;
}
.fa-font-size{
    font-size: 36px;
    color: white;
}